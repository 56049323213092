<template>
  <div class="404-page h-100 content-center">
    <section>
      <div class="container py-5 text-center">
        <h1 class="text-light display-3">404</h1>
        <p class="text-muted">
          We are sorry but your request contains bad syntax and cannot be
          fulfilled..
        </p>
        <router-link to="/" class="btn">Go Home</router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Invoice",
  methods: {
    print() {
      let myframe = document.createElement("IFRAME");

      myframe.domain = document.domain;
      myframe.style.position = "absolute";
      myframe.style.top = "-10000px";
      document.body.appendChild(myframe);
      myframe.contentDocument.write(this.$refs["invoice-body"].innerHTML);

      setTimeout(function() {
        myframe.focus();
        myframe.contentWindow.print();
        myframe.parentNode.removeChild(myframe);
      }, 3000);
      window.focus();
    }
  },
  mounted() {
    this.$navbar.name = "Page Not Found";
  }
};
</script>
